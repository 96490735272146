import React, { useRef, useState } from 'react';
import { saveAs } from 'file-saver';
import { IoExitOutline } from "react-icons/io5"
import UploadSpace from '../../components/uploadspace';
//@ts-ignore
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import useFetchFile from '../../services/fetch-file';
import Cookies from 'js-cookie';

const Home: React.FC = () => {
  const [loading, fetchFile] = useFetchFile();

  async function sendFile(file: Blob) {
    try {
      const { blob, fileName, success } = await fetchFile(file);

      if (success && blob) {
        saveAs(blob, fileName);
        NotificationManager.success('Arquivo baixado e salvo com sucesso em seus downloads.', 'SEPARADOR SEFIP');
      } else {
        NotificationManager.error('Não foi possível processar arquivo.', 'SEPARADOR SEFIP');
      }

    } catch (error) {
      throw new Error("Erro ao realizar sendFile " + error)
    }
  }



  function logOff() {
    Cookies.remove('@sefip');
    window.location.href = '/';
  }


  return (
    <div className='w-screen h-screen flex flex-col justify-center items-center bg-slate-100'>
      <span className='absolute bottom-2 text-slate-400'>Powered by Grupo HK</span>
      <button onClick={logOff} className='absolute top-6 right-6 cursor-pointer'><IoExitOutline size={26} /></button>
      <h1 className='text-5xl mb-20 text-slate-600'>Separador SEFIP</h1>
      <UploadSpace loading={loading} handleUpload={sendFile} />
      <NotificationContainer />
    </div>
  )
}

export default Home;