import Cookies from 'js-cookie';
import React, { useState } from 'react';

interface IFetchResult {
  success: boolean,
  blob?: Blob,
  fileName?: string,
  notAuth?: boolean;
}


const BASE_URL = 'https://apiteste1.grupohk.com.br:8017'

const BASE_URL_LICENCA = 'https://apiteste1.grupohk.com.br:8012'

const useFetchFile = (): [boolean, (file: Blob) => Promise<IFetchResult>] => {
  const [loading, setLoading] = useState<boolean>(false);


  async function fetchFile(file: any): Promise<IFetchResult> {
    try {
      setLoading(true);

      let fileName = '';

      const formData = new FormData();

      fileName = file.name.replace(/\./g, '') + `_${Date.now()}.zip`;

      formData.append('uploaded_file', file);

      const config = {
        method: 'POST',
        body: formData,
        headers: {
          'authorization': `Bearer ${Cookies.get('@sefip')}`
        },
      }

      const config2 = {
        method: 'POST',
        headers: {
          'authorization': `Bearer ${Cookies.get('@sefip')}`
        },
      }

      const response = await fetch(BASE_URL + '/separasefip', config)

      setLoading(false);

      if (response.status === 403) {
        return {
          success: false
        }
      } else if (response.status === 401) {
        return {
          success: false,
          notAuth: true
        }
      } else if (response.status === 200) {
        try {
          fetch(BASE_URL_LICENCA + '/sefipfileprocessed', config2)
        } catch (error) {
          console.log(error)
        }

        const _blob: Blob = await response.blob();

        return {
          success: true,
          blob: _blob,
          fileName
        };
      } else {
        return {
          success: false,
          notAuth: false
        }
      }

    } catch (error) {
      setLoading(false);
      return {
        success: false
      }
    }
  }


  return [loading, fetchFile];
}

export default useFetchFile;